/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserExcludingIdWithRelations } from './myUserExcludingIdWithRelations';
import { ItemExcludingIdWithRelations } from './itemExcludingIdWithRelations';


/**
 * (tsType: Omit<ItemBiddingWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface ItemBiddingExcludingIdWithRelations { 
    idUser?: string;
    idItem: number;
    bid: number;
    startPrice: number;
    finalPrice: number;
    isWinning?: boolean;
    isPreClosing?: boolean;
    creationTime?: string;
    user?: MyUserExcludingIdWithRelations;
    foreignKey?: any | null;
    item?: ItemExcludingIdWithRelations;
}

