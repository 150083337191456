/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { ItemPartialWithRelations } from './itemPartialWithRelations';
import { MyUserPartialWithRelations } from './myUserPartialWithRelations';


/**
 * (tsType: Partial<ItemBiddingWithRelations>, schemaOptions: { partial: true, includeRelations: true })
 */
export interface ItemBiddingPartialWithRelations { 
    id?: number;
    idUser?: string;
    idItem?: number;
    bid?: number;
    startPrice?: number;
    finalPrice?: number;
    isWinning?: boolean;
    isPreClosing?: boolean;
    creationTime?: string;
    user?: MyUserPartialWithRelations;
    foreignKey?: any | null;
    item?: ItemPartialWithRelations;
}

