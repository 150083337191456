/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserWithRelations } from './myUserWithRelations';
import { ItemWithRelations } from './itemWithRelations';


/**
 * (tsType: ItemClosingWithRelations, schemaOptions: { includeRelations: true })
 */
export interface ItemClosingWithRelations { 
    id?: number;
    idItem: number;
    idUser: string;
    bid: number;
    isPreClosing: boolean;
    isAutoBid?: boolean;
    creationTime?: string;
    item?: ItemWithRelations;
    foreignKey?: any | null;
    user?: MyUserWithRelations;
}

