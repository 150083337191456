/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserExcludingIdWithRelations } from './myUserExcludingIdWithRelations';
import { ItemExcludingIdWithRelations } from './itemExcludingIdWithRelations';


/**
 * (tsType: Omit<ItemClosingWithRelations, \'id\'>, schemaOptions: { includeRelations: true, exclude: [ \'id\' ] })
 */
export interface ItemClosingExcludingIdWithRelations { 
    idItem: number;
    idUser: string;
    bid: number;
    isPreClosing: boolean;
    isAutoBid?: boolean;
    creationTime?: string;
    item?: ItemExcludingIdWithRelations;
    foreignKey?: any | null;
    user?: MyUserExcludingIdWithRelations;
}

