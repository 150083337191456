/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * (tsType: Omit<Auction, \'id\' | \'picUrl\' | \'biddingTableUrlcs\' | \'biddingTableUrlen\' | \'auctionNoticeUrlcs\' | \'auctionNoticeUrlen\' | \'idCurrentClosingItem\' | \'isMainAuction\'>, schemaOptions: { title: \'NewAuction\', exclude: [ \'id\', \'picUrl\', \'biddingTableUrlcs\', \'biddingTableUrlen\', \'auctionNoticeUrlcs\', \'auctionNoticeUrlen\', \'idCurrentClosingItem\', \'isMainAuction\' ] })
 */
export interface NewAuction { 
    auctionNumber?: string;
    auctionName: string;
    auctionNameEN: string;
    description?: string | null;
    descriptionEN?: string | null;
    descriptionShort?: string | null;
    descriptionShortEN?: string | null;
    internetAuction?: boolean;
    exchangeRate?: number;
    visibleFrom?: string | null;
    visibleTo?: string | null;
    limitationFrom?: string | null;
    limitationTo?: string | null;
    auctionFrom?: string | null;
    timeLimit?: number;
    fee?: number;
    streamLinkActive?: string;
    streamLinkYouTube?: string | null;
    streamLinkWowza?: string | null;
    toHomePage?: boolean;
    last_modified?: string;
    toArchive?: boolean;
    logArchived?: boolean;
    isPaused?: boolean;
    hasStarted?: boolean;
    canvasId?: number | null;
    lastClosingTime?: string | null;
    endClosingTime?: string | null;
    itemMaxBid?: number | null;
    idStatus?: number;
}

