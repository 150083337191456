/**
 * iap
 * Internetovy aukcni portal
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MyUserWithRelations } from './myUserWithRelations';
import { ItemWithRelations } from './itemWithRelations';


/**
 * (tsType: ItemBiddingWithRelations, schemaOptions: { includeRelations: true })
 */
export interface ItemBiddingWithRelations { 
    id?: number;
    idUser?: string;
    idItem: number;
    bid: number;
    startPrice: number;
    finalPrice: number;
    isWinning?: boolean;
    isPreClosing?: boolean;
    creationTime?: string;
    user?: MyUserWithRelations;
    foreignKey?: any | null;
    item?: ItemWithRelations;
}

